.modal-content {
    opacity: 0;
    transform: translateY(-20px);
    transition: opacity 500ms ease-in-out, transform 500ms ease-in-out;
  }
  
  .modal-content.entering,
  .modal-content.exiting {
    opacity: 0;
    transform: translateY(-20px);
  }
  
  .modal-content.entered {
    opacity: 1;
    transform: translateY(0);
  }
  